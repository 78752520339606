import winston, { LoggerOptions } from 'winston';
import { dateString } from './time';

export function createFormat(label?: string) {
  const options = [
    (label && winston.format.label({ label })) || null,
    winston.format.printf(
      ({ level, label, message }) =>
        `${process.env.NODE_ENV !== 'production' ? `${dateString()}: ` : ''}[${level}]: ${
          label ? `[${label}]: ` : ''
        }${message}`,
    ),
  ];
  return winston.format.combine(...(options.filter(o => o) as []));
}

export const winstonBaseLoggerOptions: LoggerOptions = {
  transports: [
    new winston.transports.Console({
      stderrLevels: ['error', 'warn'],
    }),
  ],
  format: createFormat(),
};

export function createLogger(label?: string) {
  return winston.createLogger({
    ...winstonBaseLoggerOptions,
    format: createFormat(label),
  });
}

import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { default as AnonymousMain } from './components/contexts/anonymous/Main';
import { desktopNotAvailableUrl } from './consts/urls';
import DesktopNotAvailable from './components/pages/DesktopNotAvailable';
import { SnackbarProvider } from './components/providers/SnackbarProvider';
import { LoadingProvider } from './components/providers/LoadingProvider';
import { ModalProvider } from './components/providers/ModalProvider';
import { GlobalStateProvider } from './components/providers/GlobalStateProvider';
import ErrorBoundary from './components/ErrorBoundary';
import { EtherProvider } from './components/providers/EtherProvider';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: none;
    :focus {
      outline: none;
    }
    :active {
      outline: none;
    }
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #1D0E2D;
  }

  ul {
    list-style: none;
  }

  strong {
    font-weight: 800;
  }

  .page-back {
    background: linear-gradient(317.38deg, #ED2F58 0%, #ED2F58 51.36%, #FFF100 100%);
  }

  .top-bar-back {
    background: rgba(255,255,255,0.1);
    box-shadow: none;
  }
`;

function DecideContext() {
  // const { isAuthenticated } = useAuth0();

  return <AnonymousMain />;
}

function App() {
  // Example for an edge case to prevent app render for given URL
  if (window.location.pathname === desktopNotAvailableUrl) {
    return (
      <>
        <GlobalStyle />
        <DesktopNotAvailable />
      </>
    );
  }
  // End of edge case sample

  return (
    <Router>
      <GlobalStyle />
      <ErrorBoundary>
        <EtherProvider>
          <GlobalStateProvider>
            <SnackbarProvider>
              <LoadingProvider>
                <ModalProvider>
                  <DecideContext />
                </ModalProvider>
              </LoadingProvider>
            </SnackbarProvider>
          </GlobalStateProvider>
        </EtherProvider>
      </ErrorBoundary>
    </Router>
  );
}

let appComponent: () => JSX.Element;

if (process.env.NODE_ENV === 'production') {
  appComponent = App;
} else {
  appComponent = require('react-hot-loader').hot(module)(App);
}

export default appComponent;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CircularProgress, Collapse, IconButton, List, ListProps, SvgIcon } from '@material-ui/core';
import Button from '../../../../Button';
import SidebarButton from '../../../../SidebarButton';
import { useModal } from '../../../../providers/ModalProvider';
import { ConnectWallet } from '../../../../Modals';
import { useEtherContext } from '../../../../providers/EtherProvider';
// import { ReactComponent as IconGrowth } from '../../../../../assets/icons/growth.svg';
import { ReactComponent as IconBox } from '../../../../../assets/icons/package.svg';
import { ReactComponent as IconRoute } from '../../../../../assets/icons/route.svg';
// import { ReactComponent as IconTicket } from '../../../../../assets/icons/discount-voucher.svg';
import { ReactComponent as IconFund } from '../../../../../assets/icons/fund.svg';
import { ReactComponent as IconBNB } from '../../../../../assets/icons/bnb.svg';
import { ReactComponent as IconPIXU } from '../../../../../assets/icons/PIXU_oval.svg';
import { ReactComponent as IconCopy } from '../../../../../assets/icons/copy.svg';
import { useSnackbar } from '../../../../providers/SnackbarProvider';

const LeftSidebarContainer = styled.div`
  color: white;
  border-right: 1px solid #4a3e57;
  padding-right: 59px;
`;

const LogoContainer = styled.div`
  > a {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    margin: 20px 0;
    text-decoration: none;
    color: #ffffff;
    font-family: Muli;
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 38px;
    svg {
      margin-right: 12px;
      width: 50px;
      height: 50px;
    }
  }
`;

const SideItemsContainer = styled((props: ListProps) => <List component="nav" aria-label="main pages" {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const AddressContainer = styled.div`
  max-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #2e203d;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 16px;
  margin-top: 2px;
`;

const Label = styled.small`
  margin-top: 40px;
  color: #ffffff;
  font-family: Muli;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 15px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default function LeftSidebar() {
  const etherBag = useEtherContext();
  const { success } = useSnackbar();
  const { show, hide } = useModal();
  const balanceItems = [
    {
      text: 'WETH',
      secondaryText: (etherBag.balance?.SKULL && String(Number(etherBag.balance.SKULL).toFixed(4))) || '0.0000',
      Icon: <SvgIcon component={IconBNB} viewBox="0 0 2500.1 2500" />,
    },
    {
      text: 'PIXU',
      secondaryText: etherBag.balance.PIXU || '0.0000',
      Icon: <SvgIcon component={IconPIXU} viewBox="0 0 50 50" />,
    },
    {
      text: 'vipPIXU',
      secondaryText: etherBag.balance.vipPIXU || '0.0000',
      Icon: <SvgIcon component={IconPIXU} viewBox="0 0 50 50" />,
    },
  ];

  const handleClick = () => {
    show(<ConnectWallet close={hide} />);
  };

  const handleOnCopy = () => {
    const copyText: HTMLInputElement = document.createElement('input');
    document.body.appendChild(copyText);
    copyText.value = etherBag.address;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(copyText);
    success('Copied to clipboard!');
  };

  return (
    <LeftSidebarContainer>
      <LogoContainer>
        <Link to="/nft">
          <SvgIcon component={IconPIXU} viewBox="0 0 50 50" /> Pixuverse
        </Link>
      </LogoContainer>
      {!etherBag.connected && <Button onClick={handleClick}>Connect Wallet</Button>}
      <SideItemsContainer>
        <Collapse style={{ width: '100%' }} in={etherBag.connected} timeout="auto" unmountOnExit>
          <Label>Address</Label>

          <AddressContainer>
            {!etherBag.address ? (
              <CircularProgress style={{ margin: 'auto' }} size={20} disableShrink />
            ) : (
              <>
                {`${etherBag.address.substring(0, 11)}...${etherBag.address.substring(
                  etherBag.address.length - 9,
                  etherBag.address.length,
                )}`}

                <IconButton onClick={handleOnCopy} color="inherit" size="small" edge="end">
                  <SvgIcon component={IconCopy} viewBox="0 0 24 24" />
                </IconButton>
              </>
            )}
          </AddressContainer>
          <SidebarButton
            collapse
            collapseItems={balanceItems}
            Icon={<SvgIcon component={IconFund} viewBox="0 0 512 512" />}
            text={'Balance'}
          />
        </Collapse>

        <StyledLink to="/nft">
          <SidebarButton active Icon={<SvgIcon component={IconBox} viewBox="0 0 468 468" />} text={'NFT'} />
        </StyledLink>
        {/* <StyledLink to="/benefits">
          <SidebarButton Icon={<SvgIcon component={IconGrowth} viewBox="0 0 192 192" />} text={'Benefits'} />
        </StyledLink>
        <StyledLink to="/promos">
          <SidebarButton Icon={<SvgIcon component={IconTicket} viewBox="0 0 511.992 511.992" />} text={'Promos'} />
        </StyledLink>
        <StyledLink to="/roadmap">
          <SidebarButton Icon={<SvgIcon component={IconRoute} viewBox="0 0 512 512" />} text={'Roadmap'} />
        </StyledLink> */}
        <a style={{ textDecoration: 'none' }} rel="noopener noreferrer" target="_blank" href="https://pixuverse.com">
          <SidebarButton Icon={<SvgIcon component={IconRoute} viewBox="0 0 512 512" />} text={'About'} />
        </a>
      </SideItemsContainer>
    </LeftSidebarContainer>
  );
}

export function getEnv(key: string, defaultValue?: string): string {
  if (typeof window !== 'undefined' && !key.startsWith('REACT_APP')) {
    throw new Error(`React app is trying to obtain a private environment variable (${key})`);
  }
  const fromEnv = process.env[key];
  if (typeof fromEnv === 'undefined' || fromEnv === 'null' || fromEnv === '') {
    if (
      typeof defaultValue === 'undefined' ||
      ['prod'].includes((process.env.REACT_APP_ENVIRONMENT || '').toLowerCase())
    ) {
      throw new Error(`Environment variable ${key} not set.`);
    }
    return defaultValue;
  }
  return fromEnv;
}

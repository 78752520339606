import React from 'react';
import styled from 'styled-components';
import Page from '../Page';
import { ReactComponent as Logo } from '../../assets/images/Arcade-logo-color-no-bg.svg';

export const Main = styled.div`
  padding: 15px;
  color: #ffffff;
  font-family: 'Muli';
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
`;

export const Header = styled.div`
  text-align: center;
  margin: 80px 0 30px;
`;

export const Title = styled.h2`
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  text-align: center;
  padding: 0 15px;
`;

export const A = styled.a`
  color: #ffffff;
  text-decoration: none;
`;

interface MessagePageProps {
  title: string;
  message: string | JSX.Element;
}

export default function MessagePage({ title, message }: MessagePageProps) {
  return (
    <Page>
      <Main>
        <Header>
          <Logo />
        </Header>
        <Content>
          <Title>{title}</Title>
          <span>
            {message}
            <br />
            Follow our{' '}
            <A href="https://t.me/arcadebetglobal" rel="noopener noreferrer">
              <strong>Telegram</strong>
            </A>
            &nbsp; and{' '}
            <A href="https://twitter.com/ArcadeBet" rel="noopener noreferrer">
              <strong>Twitter</strong>
            </A>{' '}
            for the lastest news and updates.
          </span>
        </Content>
      </Main>
    </Page>
  );
}

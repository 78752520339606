import React, { useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import { CircularProgress, CardActions } from '@material-ui/core';
import NFTCard, {
  CardHeader,
  SmallCardContent,
  SmallTitle,
  StyledCardContainer,
  StyledTitle,
} from '../../../../NFTCard';
import api from '../../../../../utils/api';
import envs from '../../../../../../../../shared/envs/public';
import StyledButton from '../../../../Button';

const CAN_CLAIM_SKULLOID = true
interface Props {
  skull: boolean;
  getTokenIdsFor: (address: string) => Promise<{ items: number[] }>;
  getSkullsIdsFor: (address: string) => Promise<{ items: number[] }>;
  checkIfCanClaim: (address: string) => Promise<ethers.BigNumber>;
  claimSkulloidItem: (callback: Function) => Promise<void>;
  address?: string;
}

export default function MyNfts(props: Props) {
  const [nfts, setNfts] = useState<string[]>([]);
  const timer = useRef(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [claimIsLoading, setClaimIsLoading] = useState<boolean>(false);
  const [itemsToClaim, setItemsToClaim] = useState<ethers.BigNumber>(ethers.BigNumber.from(0));

  const getNfts = async function(address: string) {
    try {
      let getIdsFunction;
      if (props.skull) {
        getIdsFunction = props.getSkullsIdsFor;
      } else {
        getIdsFunction = props.getTokenIdsFor;
      }
      const { items } = await getIdsFunction(address);
      if (props.address) {
        const canClaim = await props.checkIfCanClaim(props.address);
        setItemsToClaim(canClaim);
      }
      if (items.length > 0) {
        const serialIds = items.join(',');
        const { data } = await api.get<string[]>(`theskulloids/my-nfts/${serialIds}`);
        setNfts(data);
      } else {
        setNfts([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClaim = () => {
    if (props.claimSkulloidItem) {
      setClaimIsLoading(true);
      props.claimSkulloidItem((isSuccessfullyClaimed: boolean) => {
        if (isSuccessfullyClaimed) {
          setItemsToClaim(ethers.BigNumber.from(0));
        }
        setClaimIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    const setReadyAndEmpty = () => {
      setIsLoading(false);
      setNfts([]);
    };
    if (props.address) {
      setIsLoading(true);
      getNfts(props.address);
    } else {
      setReadyAndEmpty();
    }

    timer.current = setInterval(function() {
      if (props.address) {
        getNfts(props.address);
      } else {
        setReadyAndEmpty();
      }
    }, 60_000);
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.address]);

  const noNFTYetText = props.skull ? 'You have no NFT yet' : 'You have no NFT claimed yet';
  const apiUrl = envs.webApiUrl();
  const collectionName = 'Skulloid';

  return (
    <div>
      {CAN_CLAIM_SKULLOID && props.address && !isLoading && itemsToClaim?.gt(0) && (
        <StyledCardContainer setMaxHeight={false} elevation={5}>
          <CardHeader>
            <SmallTitle>{`Claim my ${ethers.utils.formatEther(itemsToClaim)} vipPIXU tokens`}</SmallTitle>
          </CardHeader>
          <SmallCardContent>
            {claimIsLoading && <CircularProgress size={50} style={{ position: 'absolute' }} disableShrink />}
          </SmallCardContent>
          <CardActions>
            <StyledButton disabled={claimIsLoading} onClick={() => handleOnClaim()}>
              Claim
            </StyledButton>
          </CardActions>
        </StyledCardContainer>
      )}
      {isLoading && (
        <CircularProgress style={{ margin: 'auto', display: 'block', marginTop: 30 }} size={40} disableShrink />
      )}
      {!isLoading && nfts.length <= 0 && (
        <StyledCardContainer isDemo={true} elevation={5}>
          <div style={{ display: 'flex', height: 450, justifyContent: 'center', alignItems: 'center' }}>
            <StyledTitle>{props.address ? noNFTYetText : 'Please connect your wallet'}</StyledTitle>
          </div>
        </StyledCardContainer>
      )}
      {nfts.map((nft, index) => {
        return (
          <NFTCard
            key={index + 'MyNFTCard'}
            auction={{
              // remove this price from ui
              itemPrice: ethers.BigNumber.from(1),
              isFinished: true,
              catImage: `${apiUrl}/theskulloids/token-images/${nft.replace('0x0', '')}.png`,
              title: `${collectionName} ID ${nft}`,
              exist: true,
              isSoldOut: true,
              id: nft,
              maxItems: ethers.BigNumber.from(1),
              quantityPurchased: ethers.BigNumber.from(1),
              isPaused: false,
              availableItems: ethers.BigNumber.from(1),
              auctionTotalBalance: ethers.BigNumber.from(1),
            }}
            isMine
            skull={props.skull}
          />
        );
      })}
    </div>
  );
}

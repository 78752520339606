import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../assets/icons/PIXU_oval.svg';
import Page from './Page';

const Main = styled.div`
  padding: 15px;
  color: #ffffff;
  font-family: 'Muli';
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
`;

const Header = styled.div`
  text-align: center;
  margin: 80px 0 30px;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 30px;
`;

const Content = styled.div`
  text-align: center;
`;

const Button = styled.button`
  border: none;
  height: 40px;
  border-radius: 15px 15px 0 15px;
  background-color: #ed2f58;
  width: 100%;
  margin-bottom: 30px;
  color: #ffffff;
  font-family: 'Muli';
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
`;

export const A = styled.a`
  color: #ffffff;
  text-decoration: none;
`;

export default function CatastrophicError() {
  return (
    <Page>
      <Main>
        <Header>
          <Logo />
        </Header>
        <Content>
          <Title>Something went wrong</Title>
          <Button type="button" onClick={() => window.location.reload(true)}>
            Try again
          </Button>
        </Content>
      </Main>
    </Page>
  );
}

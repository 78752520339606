import React from 'react';
import styled from 'styled-components';
import Home from './pages/Home';

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export default function Main() {
  return (
    <Container>
      <Home />
    </Container>
  );
}

import { clearTimeout, setTimeout } from 'timers';
import React, { createContext, useCallback, useContext, useEffect, useState, useRef } from 'react';
import * as _ from 'lodash';
import detectEthereumProvider from '@metamask/detect-provider';
import { BigNumber, ethers } from 'ethers';
import { ExternalProvider } from '@ethersproject/providers';
// import IAuctionItems from '../../../../../shared/contracts/IAuctionItems.json';
import PIXU from '../../../../../shared/contracts/PIXU.json';
import PixuCats from '../../../../../shared/contracts/PixuCats.json';
import SkulloidMinterABI from '../../../../../shared/contracts/SKULLOD_ERC721Minter.json';
import SkulloidClaimerABI from '../../../../../shared/contracts/SKULLOID_CLAIMER_ABI.json';
// import ordinalNumber from '../../utils/ordinalNumber';
import addresses from '../../../../../shared/consts/systemAddress';
import { provider } from '../../../../../shared/consts/provider-data';
import { Result } from '../Modals';
import { useSnackbar } from './SnackbarProvider';
import { useModal } from './ModalProvider';

// const AUCTION_ADDRESS = addresses.AUCTION_ADDRESS; // Proxy
const PIXU_REWARD_ADDRESS = addresses.PIXU_REWARD_ADDRESS;

// Skulloids Addresses
const SKULLOID_CONTRACT = addresses.SKULLOID_CONTRACT;
const SKULLOID_MINTER_CONTRACT = addresses.SKULLOID_MINTER_CONTRACT;
const MOCK_WETH_TOKEN_CONTRACT = addresses.MOCK_WETH_TOKEN_CONTRACT;
const SKULLOID_CLAIMER_CONTRACT = addresses.SKULLOID_CLAIMER_CONTRACT;
const VIP_PIXU_TOKEN_ADDRESS = addresses.VIP_PIXU_TOKEN_ADDRESS;

const { ethereum } = window;
const currentProvider = new ethers.providers.JsonRpcProvider(provider.API_URL);

const PIXUContract = new ethers.Contract(PIXU_REWARD_ADDRESS, PIXU.abi, currentProvider);
const vipPIXUContract = new ethers.Contract(VIP_PIXU_TOKEN_ADDRESS, PixuCats.abi as any, currentProvider);
// const AuctionContract = new ethers.Contract(
//   AUCTION_ADDRESS,
//   IAuctionItems.abi as ethers.ContractInterface,
//   currentProvider,
// );

// Skulloid Contract
// SkulloidContract using NFT standard ABI from PixuCats.abi
const SkulloidContract = new ethers.Contract(SKULLOID_CONTRACT, PixuCats.abi, currentProvider);
const SkulloidMinterContract = new ethers.Contract(SKULLOID_MINTER_CONTRACT, SkulloidMinterABI.abi, currentProvider);
// SkulloidTokenContract ERC20 using the standard ABI from PIXU.abi
const SkulloidTokenContract = new ethers.Contract(
  MOCK_WETH_TOKEN_CONTRACT,
  PIXU.abi as ethers.ContractInterface,
  currentProvider,
);
const SkulloidClaimerContract = new ethers.Contract(SKULLOID_CLAIMER_CONTRACT, SkulloidClaimerABI.abi, currentProvider);

export interface Balances {
  BNB?: string;
  PIXU?: string;
  SKULL?: string;
  vipPIXU?: string;
  _BNB?: BigNumber;
  _PIXU?: BigNumber;
  _SKULL?: BigNumber;
  _vipPIXU?: string;
}

export interface Contracts {
  skulloid: ethers.Contract;
  auction?: ethers.Contract;
  PIXU?: ethers.Contract;
  claim?: ethers.Contract;
  skulloidMinter?: ethers.Contract;
  skulloidToken?: ethers.Contract;
}

export interface EtherProviderBag {
  setSigner: (value: ethers.providers.JsonRpcSigner) => void;
  signer: ethers.providers.JsonRpcSigner | undefined;
  address: string;
  setAddress: (values: string) => void;
  balance: Balances;
  setBalance: (values: Balances) => void;
  connected: boolean;
  setConnected: (values: boolean) => void;
  contracts: Contracts;
  setContracts: (value: Contracts) => void;
  nftItems: { loading: boolean; items: NFT[] };
  loadMore?: () => void;
  skullNFTData: SkullNFTData;
}

export interface NFT {
  itemPrice: BigNumber;
  auctionTotalBalance: BigNumber;
  maxItems: BigNumber;
  availableItems: BigNumber;
  isFinished: boolean;
  catImage?: string;
  rewardToken?: string;
  rewardsPerItem?: BigNumber;
  startBlock?: BigNumber;
  exist: boolean;
  isSoldOut: boolean;
  isPaused: boolean;
  id: number | string;
  title?: string;
  quantityPurchased?: BigNumber;
  balance?: BigNumber;
  isClaimed?: boolean;
  tokenSymbol?: string;
}

export interface SkullMintInfo {
  pricePerUnit: BigNumber;
  totalItems: BigNumber;
  mintedItems: BigNumber;
  startBlockNumber: BigNumber;
  paused: boolean;
}

export interface SkullNFTData {
  loading: boolean;
  mintInfo?: SkullMintInfo;
  tokenSymbol?: string;
}

export const EtherContext = createContext<EtherProviderBag>({} as any); // eslint-disable-line @typescript-eslint/no-explicit-any

export function EtherProvider({ children }: React.PropsWithChildren<{}>) {
  const timer = useRef(0);
  const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner>();
  const [address, setAddress] = useState<string>('');
  const [balance, setBalance] = useState<Balances>({});
  const [nftItems] = useState<{ loading: boolean; items: NFT[] }>({ loading: true, items: [] });
  const [skullNFTData, setSkullNFTData] = useState<SkullNFTData>({ loading: true });
  const [contracts, setContracts] = useState<Contracts>({
    skulloidMinter: SkulloidMinterContract,
    skulloidToken: SkulloidTokenContract,
    skulloid: SkulloidContract,
  });
  const [connected, setConnected] = useState<boolean>(false);

  // const getAuctions = useCallback(
  //   async function() {
  //     try {
  //       const auctionsLenght = await contracts.auction?.lastAuction();
  //       const currentBlock = await currentProvider.getBlockNumber()
  //       const userHistoryData = [];
  //       if (address) {
  //         const userBids = await contracts.auction?.getAllUserInfo(address);
  //         for (let index = 0; index < userBids.auctionIds.length; index++) {
  //           const quantityPurchased = userBids.totalItems[index];
  //           let isClaimed;
  //           if (quantityPurchased.gt(0)) {
  //             const res = await contracts.claim?.claimed(address, userBids.auctionIds[index].toNumber());
  //             if (res?.gt(0) && quantityPurchased?.eq(res.toNumber())) {
  //               isClaimed = true;
  //             } else {
  //               isClaimed = false;
  //             }
  //           }
  //           userHistoryData.push({
  //             auctionId: userBids.auctionIds[index].toNumber(),
  //             quantityPurchased,
  //             balance: userBids.balances[index],
  //             isClaimed,
  //           });
  //         }
  //       }
  //       const auctionItems: NFT[] = [];
  //       for (let index = 1; index < auctionsLenght.toNumber() + 1; index++) {
  //         const itemStatus = await contracts.auction?.getAuctionStatus(index);
  //         const userData = userHistoryData.find(item => item.auctionId === index);

  //         const auctionInfo = {
  //           ...userData,
  //           ...itemStatus,
  //           id: index,
  //           catImage: `${apiUrl}/sample/${index}.png`,
  //           title: `Pixucats ${ordinalNumber(index)}`,
  //         };

  //         let shouldShowItem = false
  //         if (auctionInfo.isFinished) {
  //           if (address && !auctionInfo.isClaimed && auctionInfo.quantityPurchased.gt(0)) {
  //             shouldShowItem = true
  //           }
  //         } else {
  //           shouldShowItem = true
  //         }
  //         if(shouldShowItem) {
  //           const itemInfo = await contracts.auction?.getAuctionInfo(index);
  //           itemInfo.startBlock.lte(currentBlock) && auctionItems.push({ ...auctionInfo, ...itemInfo });
  //         }
  //       }

  //       setNftItems(function (prevItems){
  //         if(prevItems.items.length > 0 && !address) {
  //           return prevItems
  //         }
  //         return { items: auctionItems.reverse(), loading: false }
  //       });

  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  //   [contracts, address, setNftItems],
  // );

  // Skulloid get mint info
  const getSkullInfo = useCallback(
    async function() {
      try {
        const mintInfo: SkullMintInfo = await contracts.skulloidMinter?.mintInfo(addresses.SKULLOID_CONTRACT);
        const tokenSymbol = await contracts.skulloidToken?.symbol();
        setSkullNFTData({ loading: false, mintInfo, tokenSymbol });
      } catch (error) {
        if ((error as any).code === 'INVALID_ARGUMENT') {
          console.log('captured invalid address');
        } else {
          console.log(error);
        }
      }
    },
    [contracts],
  );

  useEffect(() => {
    const loadBalance = async function() {
      if (signer) {
        try {
          const address = await signer.getAddress();
          const BNBBalance = await signer?.getBalance();
          const PIXUBalance = await contracts.PIXU?.balanceOf(address);
          const SkulloidBalance = await contracts.skulloidToken?.balanceOf(address);
          const vipPIXU = await vipPIXUContract.balanceOf(address);
          setBalance({
            BNB: ethers.utils.formatEther(BNBBalance || ethers.BigNumber.from(0)),
            PIXU: ethers.utils.formatEther(PIXUBalance || ethers.BigNumber.from(0)),
            SKULL: ethers.utils.formatEther(SkulloidBalance || ethers.BigNumber.from(0)),
            vipPIXU: ethers.utils.formatEther(vipPIXU || ethers.BigNumber.from(0)),
            _BNB: BNBBalance,
            _PIXU: PIXUBalance,
            _SKULL: SkulloidBalance,
            _vipPIXU: vipPIXU,
          });
        } catch (error) {
          console.log('Error on load balance: ', error);
        }
      }
    };
    if (timer.current) clearInterval(timer.current);

    timer.current = setInterval(function() {
      loadBalance();
      // temp disabled cats
      // getAuctions();
      getSkullInfo();
    }, 60_000);
    loadBalance();
    getSkullInfo();
    return () => {
      clearInterval(timer.current);
    };
    // eslint-disable-next-line
  }, [signer, contracts]);

  const values = {
    setSigner,
    signer,
    address,
    setAddress,
    balance,
    setBalance,
    nftItems,
    connected,
    setConnected,
    contracts,
    setContracts,
    skullNFTData,
  };

  return <EtherContext.Provider value={values}>{children}</EtherContext.Provider>;
}

export function useEtherContext() {
  const { error } = useSnackbar();
  const etherBag = useContext(EtherContext);
  const { show, hide } = useModal();
  const availableNetworks = [
    {
      chainId: String(provider.CHAIN_ID),
      name: provider.name,
    },
  ];

  const getMetamaskProvider = async () => {
    const provider: any = await detectEthereumProvider({ mustBeMetaMask: true });
    return provider;
  };

  const connectMetamask = async () => {
    try {
      const provider = await getMetamaskProvider();
      if (provider) {
        await provider.enable();
        setSigner(provider);
      } else {
        error('Please install MetaMask!');
      }
    } catch (err) {
      if ((err as any).code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        error('Please connect to MetaMask.');
      } else {
        console.error(err);
      }
    }
  };

  const setSigner = async (provider: ExternalProvider) => {
    const _provider = new ethers.providers.Web3Provider(provider);
    const _signer = await _provider.getSigner();
    if (_signer) {
      verifyMetamaskNetwork((ethereum as any).networkVersion, () => {
        etherBag.setSigner(_signer);
        etherBag.setConnected(true);
        setWalletData(_signer);
      });
    }
  };

  const setWalletData = async (signer: ethers.providers.JsonRpcSigner) => {
    try {
      const address = await signer.getAddress();
      if (address) {
        if (address.toLowerCase() !== etherBag.address) {
          // Pixu contracts
          // const connectedContract = etherBag.contracts.auction?.connect(signer);
          // const claimContract = ClaimContract.connect(signer);
          // Skulloid contracts
          const checkedSkulloidMinter =
            etherBag.contracts.skulloidMinter?.address === '0x'
              ? SkulloidMinterContract
              : etherBag.contracts.skulloidMinter?.connect(signer);
          const connectedSkulloidToken = SkulloidTokenContract.connect(signer);
          etherBag.setContracts({
            // auction: connectedContract,
            PIXU: PIXUContract.connect(signer),
            // claim: claimContract,
            skulloidMinter: checkedSkulloidMinter,
            skulloidToken: connectedSkulloidToken,
            skulloid: SkulloidContract.connect(signer),
          });
          etherBag.setAddress(address.toLowerCase());
        }
      }
    } catch (error) {
      console.log('Something was wrong during syncing your account');
    }
  };

  const getTokenIdsFor = async (wallet: string) => {
    return Promise.resolve({ items: [] });
  };

  const getSkullsIdsFor = async (wallet: string) => {
    try {
      const nftBalance = await etherBag.contracts.skulloid.balanceOf(wallet);
      const mapTokenIdPromises = _.range(0, nftBalance.toNumber(), 1).map((index: number) => {
        return etherBag.contracts.skulloid.tokenOfOwnerByIndex(wallet, index);
      });
      const tokenIds = await Promise.all(mapTokenIdPromises);

      const stringIds = tokenIds.reduce((acc, curr) => {
        acc.push(curr.toNumber().toString());
        return acc;
      }, []);

      return { items: stringIds };
    } catch (error) {
      console.log(error);
      return { items: [] };
    }
  };

  const checkIfCanClaim = async (address: string) => {
    return await checkIfClaimed(address);
  };

  const checkIfClaimed = async (address: string) => {
    try {
      if (etherBag.signer) {
        const Claimer = SkulloidClaimerContract.connect(etherBag.signer);
        const claimed = await Claimer.getPendingRewards(SKULLOID_CONTRACT.toLowerCase(), address);
        return claimed;
      }
      return ethers.BigNumber.from(0);
    } catch (e) {
      console.log(e);
    }
  };

  const claimSkulloidItem = useCallback(
    async function(callback) {
      const result = async () => {
        try {
          if (etherBag.signer) {
            const Claimer = SkulloidClaimerContract.connect(etherBag.signer);
            await Claimer.claim(SKULLOID_CONTRACT.toLowerCase());
            show(
              <Result
                title="Claim confirmed"
                content={`Your vipPIXU tokens have been successfully claimed. The transaction can take some time in the blockchain.`}
                close={hide}
              />,
            );
          }
          return true;
        } catch (e) {
          console.log(e);
          error('Something was wrong during the claim');
          return false;
        }
      };

      callback && callback(await result());
    }, // eslint-disable-next-line
    [etherBag.signer],
  );

  const verifyMetamaskNetwork = (chainId: string, callback?: () => void) => {
    if (!availableNetworks.some(item => item.chainId === String(Number(chainId)))) {
      show(
        <Result
          title="Invalid Network"
          content={`Please connect to a valid network\n\n - ${availableNetworks[0].name}`}
          close={hide}
        />,
      );
    } else {
      if (callback) callback();
      // hide();
    }
  };

  const handleSetSigner = async () => {
    try {
      const provider = ethereum as any;
      if (provider && provider.selectedAddress) {
        setSigner(provider);
      } else if (etherBag.connected) {
        // no address and connected, set to false
        etherBag.setConnected(false);
        etherBag.setAddress('');
      }
    } catch (error) {
      console.log('something went wrong loading data from previously loaded wallet');
    }
  };

  useEffect(() => {
    // If previous account loaded, then set signer data
    const timer = setTimeout(handleSetSigner, 500);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const provider = ethereum as any;
    const handleChainChanged = (chainId: string) => {
      verifyMetamaskNetwork(chainId, () => {
        setSigner(provider);
      });
    };

    const removeListeners = () => {
      provider?.removeListener('chainChanged', handleChainChanged);
      provider?.removeListener('accountsChanged', handleSetSigner);
      provider?.removeListener('disconnect', handleSetSigner);
    };

    if (provider) {
      removeListeners();
      provider.on('chainChanged', handleChainChanged);
      provider.on('accountsChanged', handleSetSigner);
      provider.on('disconnect', handleSetSigner);
    }

    return () => {
      removeListeners();
    };
    // eslint-disable-next-line
  }, [etherBag.connected]);

  return { connectMetamask, getTokenIdsFor, getSkullsIdsFor, claimSkulloidItem, checkIfCanClaim, ...etherBag };
}

import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import { ethers } from 'ethers';
import { Result } from '../Modals';
import { useSnackbar } from '../providers/SnackbarProvider';
import addresses from '../../../../../shared/consts/systemAddress';
import { useModal } from '../providers/ModalProvider';
import { StyledCardContainer, StyledTitle } from '../NFTCard';
import NFTBidFormContent from './FormContent';
import { AuctionProps, StyledFormCardContainer } from '.';
import { MAX_APPROVAL_VALUE } from '../../../../../shared/consts/provider-data';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const skulloidsGif = require('../../assets/images/skulloids.gif');

export default function SkulloidForm(props: AuctionProps) {
  const { history } = useReactRouter();
  const snackbar = useSnackbar();
  const { show, hide } = useModal();
  const [error, setError] = useState<string>('');

  // Render connect wallet when etherbag is not connected
  if (props.etherBag.connected === false || props.etherBag.skullNFTData.loading) {
    return (
      <StyledCardContainer isDemo={true} elevation={5}>
        <div style={{ display: 'flex', height: 450, justifyContent: 'center', alignItems: 'center' }}>
          <StyledTitle>{'Please connect your wallet'}</StyledTitle>
        </div>
      </StyledCardContainer>
    );
  } else {
    const buy = async (amount: number) => {
      const bigNumberAmount = ethers.BigNumber.from(amount);
      try {
        if (amount > 10) {
          return snackbar.error('Error, attempted to buy more than 10 items');
        }
        if (!props.etherBag.connected) {
          return snackbar.error('Please, connect your wallet first.');
        }
        const { skulloidMinter, skulloidToken } = props.etherBag.contracts;
        if (skulloidMinter && skulloidToken && props.etherBag.signer) {
          // approve before mint
          const connectedSkulloidToken = skulloidToken.connect(props.etherBag.signer);
          const allowance = await connectedSkulloidToken.allowance(
            props.etherBag.signer.getAddress(),
            addresses.SKULLOID_MINTER_CONTRACT,
          );

          if (allowance.lt(bigNumberAmount)) {
            show(
              <Result
                title="Complete Approval"
                content="Approve your wallet to start the transaction. Do not close the web app."
                close={() => {
                  hide();
                }}
              />,
            );
            const approve = await connectedSkulloidToken.approve(
              addresses.SKULLOID_MINTER_CONTRACT,
              MAX_APPROVAL_VALUE,
            );
            await approve.wait();
          }
          show(
            <Result
              title="Complete transaction"
              content="Confirm your transaction in your wallet and wait for the blockchain confirmations. This may take a few seconds. Do not close the web app."
              close={() => {
                hide();
              }}
            />,
          );
          const result = await skulloidMinter.mint(addresses.SKULLOID_CONTRACT, bigNumberAmount);
          show(
            <Result
              title="Waiting for Transaction Confirmation"
              content={`The transaction is pending confirmation.`}
              close={() => {
                hide();
                history.push('/');
              }}
            />,
          );
          await result.wait();
          hide();
          show(
            <Result
              title="The mint is completed"
              content={`Thanks for your purchase, now you can see your NFT on "My NFT" tab.`}
              close={() => {
                hide();
                history.push('/');
              }}
            />,
          );
          // Your transaction ID: ${result.hash}
        }
      } catch (error) {
        hide();
        if (error.data?.message.includes('AUCTION_ISNT_AVAILABLE')) {
          setError(`The NFT is not available.`);
        }
        console.log('error mint: ', error);
        snackbar.error('The mint can not be completed.');
      }
    };

    const title = 'Buy Skulloids',
      subtitle = 'Set the number of NFTs you want to buy at the current price.';

    const skulloidNFTData = props.etherBag.skullNFTData;

    const auction = {
      catImage: skulloidsGif,
      title: 'Skulloid NFTs',
      isPaused: skulloidNFTData.mintInfo!.paused,
      itemPrice: skulloidNFTData.mintInfo!.pricePerUnit,
      tokenSymbol: skulloidNFTData.tokenSymbol!,
      availableItems: ethers.BigNumber.from(10),
    } as any;

    return (
      <StyledFormCardContainer elevation={5}>
        <NFTBidFormContent
          title={title}
          subtitle={subtitle}
          addAmounts={[1, 10]}
          currentBalance={props.etherBag.balance._SKULL}
          error={error}
          setError={setError}
          bid={buy}
          auction={auction}
          skull={true}
        />
      </StyledFormCardContainer>
    );
  }
}

import React, { useState, useContext } from 'react';

const GlobalStateContext = React.createContext<{ currentHomeTab: number; setCurrentHomeTab: (index: number) => void }>(
  {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
);

export function GlobalStateProvider({ children }: React.PropsWithChildren<{}>) {
  const [currentHomeTab, setCurrentHomeTab] = useState(0);
  return (
    <GlobalStateContext.Provider value={{ currentHomeTab, setCurrentHomeTab }}>{children}</GlobalStateContext.Provider>
  );
}

export function useGlobalState() {
  return useContext(GlobalStateContext);
}

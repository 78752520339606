import { CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { ethers } from 'ethers';
import ordinalNumber from '../../utils/ordinalNumber';
import { apiUrl } from '../../../../../shared/consts/general';
import { NFT } from '../providers/EtherProvider';
import { useModal } from '../providers/ModalProvider';
import { Result } from '../Modals';
import { useSnackbar } from '../providers/SnackbarProvider';
import NFTBidFormContent from './FormContent';
import { AuctionProps, StyledFormCardContainer } from '.';

export default function NFTBidForm(props: AuctionProps) {
  const { match, history } = useReactRouter();
  const { show, hide } = useModal();
  const snackbar = useSnackbar();
  const nftId = (match.params as any).nftId;
  const [auction, setAuction] = useState<NFT>();
  const [error, setError] = useState<string>('');

  const bid = async (price: string, auctionId: number) => {
    try {
      if (!props.etherBag.connected) {
        return snackbar.error('Please, connect your wallet first.');
      }
      if (props.etherBag.contracts.auction) {
        show(
          <Result
            title="Complete transaction"
            content="Approve your transaction in your wallet and wait for the blockchain confirmation. This may take a few seconds. Do not close the web app."
            close={() => {
              hide();
            }}
          />,
        );
        const result = await props.etherBag.contracts.auction.bid(auctionId, {
          value: ethers.utils.parseEther(price),
        });
        show(
          <Result
            title="Waiting for Transaction Confirmation"
            content={`The transaction is pending confirmation in Binance Smart Chain.`}
            close={() => {
              hide();
              history.push('/');
            }}
          />,
        );
        await result.wait();
        hide();
        show(
          <Result
            title="The bid is completed"
            content={`You now have your $PIXU reward tokens. The NFTs will be ready to claim once the bid is finished.`}
            close={() => {
              hide();
              history.push('/');
            }}
          />,
        );
        // Your transaction ID: ${result.hash}
      }
    } catch (error) {
      if (error.data?.message.includes('AUCTION_ISNT_AVAILABLE')) {
        hide();
        setError(`The NFT is not available.`);
        getAuction();
      }
      snackbar.error('The bid can not be completed.');
    }
  };

  const getAuction = async () => {
    try {
      if (props.etherBag.contracts.auction) {
        const auctionInfo = await props.etherBag.contracts.auction.getAuctionInfo(nftId);
        const itemStatus = await props.etherBag.contracts.auction.getAuctionStatus(nftId);
        if (itemStatus.isFinished) {
          setError(`The NFT is not available`);
        }
        setAuction({
          ...auctionInfo,
          ...itemStatus,
          catImage: `${apiUrl}/sample/${nftId}.png`,
          title: `Pixu Cats ${ordinalNumber(Number(nftId))}`,
        });
      } else {
        //
      }
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    getAuction();
    // eslint-disable-next-line
  }, [props.etherBag.contracts.auction]);

  if (!auction)
    return <CircularProgress style={{ margin: 'auto', display: 'block', marginTop: 50 }} size={40} disableShrink />;

  const title = "Buy NFT",
        subtitle = "Set the number of NFTs you want to buy at the current price."
  
  return (
    <StyledFormCardContainer elevation={5}>
      <NFTBidFormContent
        title={title}
        subtitle={subtitle}
        addAmounts={[1,10,100]}
        currentBalance={props.etherBag.balance._BNB}
        error={error}
        setError={setError}
        bid={bid}
        auction={{ ...auction, id: nftId }}
      />
    </StyledFormCardContainer>
  );
}

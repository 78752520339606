import React from 'react';
import styled from 'styled-components';
import { Icon, IconButton, SvgIcon } from '@material-ui/core';
import { ReactComponent as IconLedger } from '../assets/icons/ledger.svg';
import { ReactComponent as IconMetamask } from '../assets/icons/metamask.svg';
import { ReactComponent as IconLink } from '../assets/icons/walletlink.svg';
import { ReactComponent as IconConnect } from '../assets/icons/walletconnect.svg';
import { useEtherContext } from './providers/EtherProvider';
import { StyledCardContent, StyledCardHeader } from './CardComponents';

interface Props {
  close: Function;
}

const WalletsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 7.5px;
  > * {
    flex: 0 1 calc(50% - 15px);
  }
`;

const Wallet = styled.div`
  color: #ffffff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease;
  border-radius: 10px;
  padding: 7.5px;
  margin-bottom: 30px;
  &:hover {
    background-color: #413053;
  }
  small {
    margin-left: 3px;
    font-size: 75%;
  }
  svg {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    color: white;
  }
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Muli;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  color: white;
  min-height: 178px;
`;

export function ConnectWallet(props: Props) {
  const etherBag = useEtherContext();

  const wallets = [
    {
      name: 'Metamask',
      Icon: <SvgIcon component={IconMetamask} viewBox="0 0 318.6 318.6" />,
      handleOnClik: () => handleClick(etherBag.connectMetamask),
      isAvailable: true,
    },
    {
      name: 'Ledger',
      Icon: <SvgIcon component={IconLedger} viewBox="0 0 20 20" />,
      isAvailable: false,
    },
    {
      name: 'WalletConnect',
      Icon: <SvgIcon component={IconConnect} viewBox="0 0 300 185" />,
      isAvailable: false,
    },
    {
      name: 'WalletLink',
      Icon: <SvgIcon component={IconLink} viewBox="0 0 383 383" />,
      isAvailable: false,
    },
  ];

  const handleClick = (task: Function) => {
    task();
    props.close();
  };

  return (
    <>
      <StyledCardHeader
        action={
          <IconButton style={{ color: 'white' }} onClick={() => props.close()} aria-label="close">
            <Icon>close</Icon>
          </IconButton>
        }
        title="Connect to NFT"
        subheader="Select your wallet provider"
      />
      <StyledCardContent>
        <WalletsContainer>
          {wallets.map(wallet => (
            <Wallet key={wallet.name} onClick={wallet.handleOnClik || undefined} role="button">
              {wallet.Icon}
              <span>{wallet.name}</span>
              {!wallet.isAvailable && <small>(Coming soon)</small>}
            </Wallet>
          ))}
        </WalletsContainer>
      </StyledCardContent>
    </>
  );
}

export function Result(props: { content: string; title: string; close?: Function }) {
  return (
    <>
      <StyledCardHeader
        action={
          props.close ? (
            <IconButton style={{ color: 'white' }} onClick={() => props.close && props.close()} size="small" aria-label="close">
              <Icon>close</Icon>
            </IconButton>
          ) : null
        }
        title={props.title}
      />
      <StyledCardContent>
        <Message>
          <p>{props.content}</p>
        </Message>
      </StyledCardContent>
    </>
  );
}

import React from 'react';
import styled from 'styled-components';
import MessagePage, { A, Title } from './MessagePage';

const StyledDiv = styled.div`
  max-width: 600px;
  display: inline-block;
  margin-bottom: 20px;
`;

const StyledTitle = styled(Title)`
  margin: 20px 0;
`;

export default function DesktopNotAvailable() {
  return (
    <MessagePage
      title="Desktop version not available."
      message={
        <>
          <span>
            To learn more about Arcade and the huge prizes they're preparing visit{' '}
            <A href="https://arcade.global/">
              <strong>Arcade Home Page</strong>
            </A>
          </span>
          <StyledTitle>Open Arcade in you mobile browser.</StyledTitle>
          <StyledDiv>
            <span>
              We have FREE Lotto and other games with amazing prizes straight from your mobile device. Invite your
              friends and EARN affiliate commissions in the coolest gaming community online. Join Arcade, be part of the
              game!
            </span>
          </StyledDiv>
        </>
      }
    />
  );
}

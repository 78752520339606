import React, { createContext, useState, useContext, useEffect, SyntheticEvent, useCallback, useMemo } from 'react';
import { Snackbar, SnackbarContent, IconButton, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { ReactComponent as IconClose } from '../../assets/images/X.svg';

type NotificationType = 'success' | 'warning' | 'error' | 'info';

type SnackbarContextType = {
  [k in NotificationType]: (message: string) => void;
};

const SnackBarContext = createContext<SnackbarContextType>({} as any); // eslint-disable-line @typescript-eslint/no-explicit-any

interface SnackbarType {
  message: string | JSX.Element;
  type: NotificationType;
}

interface SnackbarState {
  open: boolean;
  current: SnackbarType | null;
}

const snackbarTypeConfigurations: { [k in NotificationType]: { style: {} } } = {
  success: {
    style: {
      border: '1px solid green',
    },
  },
  warning: {
    style: {
      border: '1px solid yellow',
    },
  },
  error: {
    style: {
      border: '1px solid red',
    },
  },
  info: {
    style: {
      color: 'white',
      fontSize: '14px',
      fontFamily: 'Muli',
      fontWeight: 800,
      lineHeight: '18px',
      border: 'none',
      borderRadius: '15px 15px 0 15px',
      backgroundColor: '#ED2F58',
    },
  },
};

const TransitionDown = (props: TransitionProps) => {
  return <Slide {...props} direction="down" />;
};

export function SnackbarProvider({ children }: React.PropsWithChildren<{}>) {
  const [alerts, setAlerts] = useState<SnackbarType[]>([]);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({ open: false, current: null });
  const { current, open } = snackbarState;

  const addAlert = useCallback((alert: SnackbarType) => setAlerts(alerts => [...alerts, alert]), []);

  const snackbarFunctions = useMemo(() => {
    const info = (message: string) => addAlert({ message, type: 'info' });
    const error = (message: string) => addAlert({ message, type: 'error' });
    const success = (message: string) => addAlert({ message, type: 'success' });
    const warning = (message: string) => addAlert({ message, type: 'warning' });
    const functionsObject: SnackbarContextType = { error, info, success, warning };
    return functionsObject;
  }, [addAlert]);

  const processQueue = useCallback(() => {
    if (alerts.length > 0) {
      setSnackbarState({
        current: alerts.shift()!,
        open: true,
      });
      setAlerts(alerts);
    }
  }, [alerts, setSnackbarState, setAlerts]);

  const handleClose = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: SyntheticEvent<any, Event>, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarState({ open: false, current: null });
    },
    [setSnackbarState],
  );

  const handleExited = useCallback(() => {
    processQueue();
  }, [processQueue]);

  useEffect(() => {
    if (alerts.length > 0) {
      processQueue();
    }
  }, [alerts, processQueue]);

  return (
    <SnackBarContext.Provider value={{ ...snackbarFunctions }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        onExited={handleExited}
        TransitionComponent={TransitionDown}
      >
        <SnackbarContent
          style={current ? snackbarTypeConfigurations[current.type].style : {}}
          message={<span id="message-id">{current && current.message}</span>}
          action={[
            current && (
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={handleClose}
                style={{ height: 20, width: 20, padding: 0 }}
              >
                <IconClose height={10} width={10} />
              </IconButton>
            ),
          ]}
        />
      </Snackbar>
    </SnackBarContext.Provider>
  );
}

export const useSnackbar = () => useContext(SnackBarContext);

import { CardActions, CardContent, CardHeader } from '@material-ui/core';
import styled from 'styled-components';

export const StyledCardHeader = styled(CardHeader)`
  color: #ffffff;
  .MuiCardHeader-title {
    font-family: Muli;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 20px;
  }
  .MuiCardHeader-subheader {
    margin-top: 18px;
    color: #ffffff;
    font-family: Muli;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 30px;
  }
`;
export const StyledCardContent = styled(CardContent)``;
export const StyledCardActions = styled(CardActions)``;

import React, { createContext, useState, useCallback, useContext } from 'react';
import { Card } from '@material-ui/core';
import styled from 'styled-components';

const ModalContainer = styled.div<{ isShowing: boolean }>`
  display: flex;
  z-index: ${props => (props.isShowing ? '1000' : '-100')};
  visibility: ${props => (props.isShowing ? 'visible' : 'hidden')};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

const StyledModal = styled(Card)`
  min-height: 306px;
  width: 600px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: rgb(46, 32, 61);
`;

function ModalComponent({
  isShowing = true,
  content,
  close,
}: {
  isShowing?: boolean;
  content?: React.ReactElement | null;
  close: Function;
}) {
  return (
    <ModalContainer isShowing={isShowing}>
      <StyledModal>{content}
      </StyledModal>
    </ModalContainer>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ModalContext = createContext<{
  show: (content: React.ReactElement) => void;
  hide: () => void;
}>({} as any);

export function ModalProvider({ children }: React.PropsWithChildren<{}>) {
  const [count, setCount] = useState(0);
  const [content, setContent] = useState<null | React.ReactElement>(null);
  const show = useCallback((_content) => {
    setContent(_content);
    setCount(count => 1);
  }, []);
  const hide = useCallback(() => {
    setContent(null);
    setCount(count => 0);
  }, []);
  return (
    <ModalContext.Provider value={{ show, hide }}>
      <ModalComponent content={content} close={hide} isShowing={count > 0} />
      {children}
    </ModalContext.Provider>
  );
}

/**
 * Helper that shows a modal.
 *
 */
export function useModal<T>() {
  const { show, hide } = useContext(ModalContext);

  return { show, hide };
}

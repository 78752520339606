import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { getErrorMessage } from '../../../../../shared/utils/errors';
import { useSnackbar } from './SnackbarProvider';

const LoadingSpinnerContainer = styled.div<{ isShowing: boolean }>`
  display: flex;
  z-index: ${props => (props.isShowing ? '1000' : '-100')};
  visibility: ${props => (props.isShowing ? 'visible' : 'hidden')};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

function LoadingSpinner({ isShowing = true }: { isShowing?: boolean }) {
  return (
    <LoadingSpinnerContainer isShowing={isShowing}>
      <CircularProgress />
    </LoadingSpinnerContainer>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LoadingContext = createContext<{ show: () => void; hide: () => void }>({} as any);

export function LoadingProvider({ children }: React.PropsWithChildren<{}>) {
  const [count, setCount] = useState(0);
  const show = useCallback(() => {
    setCount(count => count + 1);
  }, []);
  const hide = useCallback(() => {
    setCount(count => count - 1);
  }, []);
  return (
    <LoadingContext.Provider value={{ show, hide }}>
      <LoadingSpinner isShowing={count > 0} />
      {children}
    </LoadingContext.Provider>
  );
}

/**
 * Helper that shows a loading animation while doing async work and
 * handles errors showing a CatastrophicError page.
 *
 * @param work
 */
export function useLoadingDuringRender<T>(work: () => Promise<T>) {
  const [promise] = useState(work);
  const { show, hide } = useContext(LoadingContext);
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState();

  useEffect(() => {
    show();
    promise
      .then(data => {
        setData(data);
      })
      .catch(setError)
      .finally(hide);
  }, [promise, show, hide]);

  if (error) {
    throw error;
  }

  return data;
}

/**
 * Helper that shows a loading animation while doing async work and
 * handles errors by displaying the error message and finally re-throwing.
 *
 * @param work
 */
export function useLoading<T>() {
  const { info } = useSnackbar();
  const { show, hide } = useContext(LoadingContext);
  const loading = useCallback(
    async (work: Promise<T> | (() => Promise<T>)) => {
      try {
        show();
        const promise = typeof work === 'function' ? work() : work;
        const result = await promise;
        return result;
      } catch (loadingError) {
        const message = getErrorMessage(loadingError);
        info(message);
        // shouldThrow Error?
        throw loadingError;
      } finally {
        hide();
      }
    },
    [info, show, hide],
  );
  return loading;
}

import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { setAppElement } from 'react-modal';
import winston from 'winston';
import * as Sentry from '@sentry/browser';
import { StylesProvider } from '@material-ui/core/styles'
import { winstonBaseLoggerOptions } from '../../../shared/utils/winston';
import publicEnvs from '../../../shared/envs/public';
import { dsn } from '../../../shared/consts/sentry';
import { isAxiosError, serializeAxiosError } from '../../../shared/utils/errors';
import App from './App';
import * as serviceWorker from './serviceWorker';

winston.configure(winstonBaseLoggerOptions);
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn,
    environment: publicEnvs.environment.toUpperCase(),
    beforeSend(event, hint) {
      if (!event.extra) {
        event.extra = {};
      }
      if (
        hint &&
        hint.originalException &&
        typeof hint.originalException !== 'string' &&
        isAxiosError(hint.originalException)
      ) {
        if (!hint.originalException.response || hint.originalException.response.status === 0) {
          return null;
        }
        event.extra.axios = serializeAxiosError(hint.originalException);
      }
      return event;
    },
  });
}
setAppElement('body');

// should trigger github action
ReactDOM.render(
  <StylesProvider injectFirst>
    <App />
  </StylesProvider>,
  document.getElementById('root'),
);

if (process.env.NODE_ENV === 'production') {
  console.log = () => null
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting;

//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener('statechange', event => {
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         if (event && event.target && (event.target as any).state === 'activated') {
//           window.location.reload();
//         }
//       });
//       waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//     }
//   },
// });
serviceWorker.unregister();

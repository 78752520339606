import { domain } from '../consts/general';
import { shouldUseRinkeby } from '../consts/provider-data';
import { getEnv } from './utils';

const publicEnvs = {
  get environment(): string {
    return getEnv('REACT_APP_ENVIRONMENT', 'LOCAL').toLowerCase();
  },
  get wordpressUrl() {
    return getEnv('REACT_APP_WORDPRESS_URL', 'https://content.dev.arcade.global');
  },
  get auth0Config(): { domain: string; clientId: string; audience: string } {
    return JSON.parse(
      getEnv(
        'REACT_APP_AUTH0_CONFIG',
        JSON.stringify({
          domain: 'arcade-network-dev.eu.auth0.com',
          clientId: 'IA8E7HSgi9KGEKjFwiJAac7tiFJB5GxA',
          audience: 'https://api.dev.arcade.global',
        }),
      ),
    );
  },
  get appPusherConfig(): { key: string; cluster: string } {
    return JSON.parse(
      getEnv(
        'REACT_APP_PUSHER_CONFIG',
        JSON.stringify({
          key: '33e50b239d672536942a',
          cluster: 'us2',
        }),
      ),
    );
  },
  domain(production?: boolean) {
    if (typeof production === 'undefined') {
      production = process.env.NODE_ENV === 'production';
    }
    if (production) {
      return domain;
    }
    return 'localhost';
  },
  get appPort() {
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'develop') {
      return 80;
    }
    return 3000;
  },
  get apiPort() {
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'develop') {
      return 80;
    }
    return 3001;
  },
  get skulloidsApiPort() {
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'develop') {
      return 80;
    }
    return 3004;
  },
  buildUrl(subdomain?: 'app' | 'api' | 'r', production?: boolean) {
    if (typeof production === 'undefined') {
      production = process.env.NODE_ENV === 'production';
    }
    const protocol = production ? 'https' : 'http';
    const port = production || !subdomain ? '' : `:${subdomain === 'app' ? this.appPort : this.skulloidsApiPort}`;
    const environment =
      !production || publicEnvs.environment === 'prod'
        ? ''
        : `${publicEnvs.environment !== 'local' ? publicEnvs.environment : 'dev'}.`;
    const subdomainFinal = subdomain && production ? `${subdomain === 'app' ? 'play' : subdomain}.` : '';
    return `${protocol}://${subdomainFinal}${environment}${this.domain(production)}${port}`;
  },
  apiBaseUrl(useNgrok?: boolean) {
    if (process.env.NODE_ENV !== 'production' && useNgrok) {
      if (!process.env.REACT_APP_API_NGROK_BASE_URL) {
        throw new Error('REACT_APP_API_NGROK_BASE_URL is empty');
      }
      return process.env.REACT_APP_API_NGROK_BASE_URL;
    }
    if (shouldUseRinkeby && process.env.NODE_ENV === 'develop') {
      // will change to https://dnft.pixuverse.com in the future
      return 'https://dnft.pixuverse.com';
    }
    return process.env.NODE_ENV !== 'production' ? this.buildUrl('api') : 'https://nft.pixuverse.com';
  },
  webApiUrl(){
    if(process.env.REACT_APP_ENV === 'production')  {
      return 'https://nft.pixuverse.com';
    } else if(process.env.REACT_APP_ENV === 'development') {
      return 'https://dnft.pixuverse.com';
    } else {
      return this.buildUrl('api')
    }
  },
  get appBaseUrl(): string {
    return this.buildUrl('app');
  },
  redirectsBaseUrl(useNgrok?: boolean): string {
    if (process.env.NODE_ENV !== 'production' && useNgrok) {
      if (!process.env.REACT_APP_API_NGROK_BASE_URL) {
        throw new Error('REACT_APP_API_NGROK_BASE_URL is empty');
      }
      return process.env.REACT_APP_API_NGROK_BASE_URL;
    }
    return this.buildUrl('r');
  },
  get paypalClientID(): string {
    return getEnv(
      'REACT_APP_PAYPAL_ID',
      'AcoB0pbBoAy3VYbgsQO_-Dk4_JpBmLsgOFJeXo9mYAGkt4fKrXq8fSJhFXLAlmRcaTERgmVzp6DrR3g8',
    );
  },
};

export default publicEnvs;

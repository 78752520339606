import React, { useState, useCallback, useMemo } from 'react';
import { Button } from '@material-ui/core';
import { BigNumber, ethers } from 'ethers';
import styled from 'styled-components';
import { NFT } from '../providers/EtherProvider';
import StyledButton from '../Button';

const CardBuyHeader = styled.div`
  height: 230px;
  position: relative;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.25);
  }
`;

const CardBuyContent = styled.div`
  padding: 30px 15px;
  border-top: 1px solid #4a3e57;
  border-bottom: 1px solid #4a3e57;
  display: flex;
  flex-direction: column;
`;

const CardBuyActions = styled.div`
  margin-top: 30px;
  padding: 20px;
  padding-top: 0;
`;

const TimeContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: right;
  top: 20px;
  right: 20px;
`;

const Title = styled.h2`
  font-family: 'Fugaz One';
  font-size: 24px;
  letter-spacing: 0;
  line-height: 35px;
  text-align: center;
  z-index: 1;
`;

const Price = styled.h1`
  color: #ffffff;
  font-family: Muli;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 45px;
  text-align: center;
  z-index: 1;
`;

const AddNumbers = styled(Button)`
  border: none;
  border-radius: unset;
  background-color: #2e203d;
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  padding: 8px 0px;
  text-align: center;
  flex: 1;
  &:hover {
    background-color: #2e203d;
  }
  :disabled {
    opacity: 0.5;
  }
  &:not(:last-child) {
    margin-right: 15px;
  }
`;

const ClearButton = styled(Button)`
  min-height: 40px;
  border-radius: 15px 15px 0 15px;
  color: #fff;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  margin-left: calc(100% - 65px);
  text-transform: none;
  &:disabled {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const ContentTitle = styled.span`
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 5px;
`;
const ContentSubTitle = styled.span`
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 15px;
`;

const FigurizedContainer = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  border-radius: 15px 15px 0 15px;
  overflow: hidden;
  margin-bottom: 15px;
`;
const Quantity = styled.div`
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
  vertical-align: middle;
  display: flex;
  flex: 1 1 100%;
  margin-top: 15px;
  > label {
    margin-right: 15px;
  }
  ${FigurizedContainer} {
    width: 100%;
  }
`;

const QuantityValue = styled.span`
  background-color: #2e203d;
  width: 100%;
  text-align: center;
`;

const Error = styled.span`
  width: 100%;
  font-family: Muli;
  font-size: 20px;
  color: #b43c3c;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
  vertical-align: middle;
  text-align: center;
`;

type BuyForm = (amount: number) => Promise<void>

export type FormContentProps = {
  title: string;
  subtitle: string;
  addAmounts: number[];
  auction: NFT;
  error: string;
  setError: (value: string) => void;
  bid: (...args: any[]) => Promise<void> | BuyForm;
  currentBalance: BigNumber | undefined;
  skull?: boolean;
}

export default function NFTBidFormContent(props: FormContentProps) {
  const [amount, setAmount] = useState(0);
  const addAmount = useCallback(
    (diff: number) => {
      setAmount(amount + diff);
    },
    [amount, setAmount],
  );

  const totalPrice = useMemo(() => {
    const price = props.auction.itemPrice.mul(amount);
    if (props.auction.availableItems.lt(amount)) {
      props.setError(`Max (${props.auction.availableItems.toString()}) items per purchase`);
    } else if (props.currentBalance && price.gt(props.currentBalance)) {
      props.setError(`Not enought balance to buy ${amount} items (${ethers.utils.formatEther(price)} ${props.auction.tokenSymbol})`);
    } else {
      props.setError('');
    }
    return price;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const handleSubmit = () => {
    if(props.skull){
      props.bid(amount);
    } else {
      props.bid(ethers.utils.formatEther(totalPrice), props.auction.id);
    }
  }

  return (
    <>
      <TimeContainer>
        {/* <StyledLabel>Time left</StyledLabel>
        <StyledTime>01:45:58</StyledTime> */}
      </TimeContainer>
      <CardBuyHeader
        style={{
          background: `url(${props.auction.catImage})`,
        }}
      >
        <Title>{props.auction.title}</Title>
        <Price>{ethers.utils.formatEther(props.auction?.itemPrice)} {props.auction.tokenSymbol}</Price>
      </CardBuyHeader>
      <CardBuyContent>
        <ContentTitle>{props.title}</ContentTitle>
        <ContentSubTitle>{props.subtitle}</ContentSubTitle>
        <Quantity>
          <label aria-label="Quantity">Quantity</label>{' '}
          <FigurizedContainer>
            <QuantityValue>{amount}</QuantityValue>
          </FigurizedContainer>
        </Quantity>
        <FigurizedContainer>
          {props.addAmounts.map(diff => {
            const shouldDisable = false;
            return (
              <AddNumbers key={diff} onClick={() => addAmount(diff)} disabled={shouldDisable}>
                +{diff}
              </AddNumbers>
            );
          })}
        </FigurizedContainer>
        <ClearButton disabled={amount <= 0} onClick={() => setAmount(0)}>
          Clear
        </ClearButton>
        {props.error && <Error>{props.error}</Error>}
      </CardBuyContent>
      <CardBuyActions>
        <StyledButton
          onClick={handleSubmit}
          disabled={amount <= 0 || !!props.error}
        >
          <span className="amount">{`${ethers.utils.formatEther(totalPrice)} ${props.auction.tokenSymbol}`}</span>
        </StyledButton>
      </CardBuyActions>
    </>
  );
}
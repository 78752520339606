import { Card, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { ethers } from 'ethers';
import useReactRouter from 'use-react-router';
import styled, { css } from 'styled-components';
import StyledButton from './Button';
import { NFT } from './providers/EtherProvider';

interface Props {
  auction?: NFT;
  isDemo?: boolean;
  skull?: boolean;
  isMine?: boolean;
  claim?: Function;
}

export const StyledCardContainer = styled(({ isDemo, setMaxHeight = true, float, skull, ...other }) => (
  <Card {...other} />
))`
         position: relative;
         width: 100%;
         color: #ffffff;
         margin: auto;
         margin-top: 22px;
         ${props => `background-color: ${props.isDemo ? 'black' : '#181120'};`}
         ${props => (props.setMaxHeight ? 'min-height: 450px;' : '')}
         ${props =>
           props.float
             ? css`
                 position: sticky;
                 top: 1rem;
                 z-index: 1;
               `
             : ''}
  
       `;

export const CardHeader = styled.div`
  margin: 0;
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .left,
  .right {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right {
    justify-content: flex-start;
    text-align: right;
    visibility: hidden;
  }
  .priceButton {
    margin-top: 2px;
    min-width: 42%;
  }
`;

export const StyledTitle = styled.h2`
  font-family: 'Fugaz One';
  font-size: 24px;
  letter-spacing: 0;
  line-height: 35px;
  margin-bottom: 5px;
`;

export const SmallTitle = styled.h2`
  font-family: 'Fugaz One';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 35px;
  margin-bottom: 5px;
`;

export const StyledLabel = styled.span`
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
`;

export const CardContent = styled.div`
  padding: 10px 30px;
  margin: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SmallCardContent = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CardActions = styled.div`
  margin-top: 30px;
  padding: 20px;
  padding-top: 0;
  display: flex;
  justify-content: flex-end;
  ${StyledButton} {
    width: 50%;
  }
`;

export default function NFTCard(props: Props) {
  const { history } = useReactRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [isAlreadyClaimed, setIsAlreadyClaimed] = useState(false);

  const handleOnClaim = () => {
    if (props.claim) {
      setIsLoading(true);
      props.claim(String(props.auction?.id), (error: boolean) => {
        setIsLoading(false);
        !error && setIsAlreadyClaimed(true);
      });
    }
  };

  if (props.isDemo) {
    return (
      <StyledCardContainer isDemo={true} elevation={5}>
        <img
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            opacity: 0.6,
          }}
          alt="nft cat"
          src={props.auction?.catImage || ''}
          className="img"
        ></img>
        <StyledTitle
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
          }}
        >
          {props.auction?.title}
        </StyledTitle>
      </StyledCardContainer>
    );
  }

  //TODO: agregar loader
  if (!props.auction || isAlreadyClaimed || (!props.isMine && props.auction.isFinished && props.auction.isClaimed)) {
    return null;
  }

  if (props.skull) {
    return (
      <StyledCardContainer elevation={5}>
        <CardHeader>
          <div className="left">
            {!props.isMine && (
              <>
                <StyledTitle>{props.auction.title}</StyledTitle>
                <StyledLabel>Price</StyledLabel>
              </>
            )}
            {props.isMine && <SmallTitle>{`${props.auction?.title}`}</SmallTitle>}
          </div>
          {!props.isMine && (
            <div className="priceButton">
              <StyledButton withLoadIndicator loadAmount={8}>
                {ethers.utils.formatEther(props.auction.itemPrice)} {props.auction.tokenSymbol}
              </StyledButton>
            </div>
          )}
        </CardHeader>
        <CardContent>
          {isLoading && <CircularProgress size={50} style={{ position: 'absolute' }} disableShrink />}
          <img
            style={{
              margin: 'auto',
              width: '100%',
              minHeight: 320,
            }}
            alt={props.auction?.title}
            src={props.auction?.catImage || ''}
            className="img"
          ></img>
        </CardContent>
        {!props.isMine && !props.auction.isFinished && (
          <CardActions>
            <StyledButton disabled={props.auction.isPaused} onClick={() => history.push(`/buy-skulloids`)}>
              {props.auction.isPaused ? 'Unavailable' : 'Buy'}
            </StyledButton>
          </CardActions>
        )}
      </StyledCardContainer>
    );
  }

  return (
    <StyledCardContainer elevation={5}>
      <CardHeader>
        <div className="left">
          {!props.isMine && (
            <>
              <StyledTitle>{`${props.auction?.title} - ${props.auction?.maxItems.toString()} NFTs`}</StyledTitle>
              <StyledLabel>Price</StyledLabel>
            </>
          )}
          {props.isMine && <StyledTitle>{`${props.auction?.title}`}</StyledTitle>}
        </div>
        {/* <div className="right">
            <StyledLabel>Price change in:</StyledLabel>
            <StyledTime>01:45:58</StyledTime>
          </div> */}
        {!props.isMine && (
          <div className="priceButton">
            <StyledButton withLoadIndicator loadAmount={8}>
              {ethers.utils.formatEther(props.auction?.itemPrice)} BNB
            </StyledButton>
          </div>
        )}
      </CardHeader>
      <CardContent>
        {isLoading && <CircularProgress size={50} style={{ position: 'absolute' }} disableShrink />}
        <img alt={props.auction?.title} src={props.auction?.catImage || ''} className="img"></img>
      </CardContent>
      <CardActions>
        {!props.isMine && !props.auction.isFinished && (
          <StyledButton disabled={props.auction.isSoldOut} onClick={() => history.push(`/bid/${props.auction?.id}`)}>
            {props.auction.isSoldOut ? 'Sold Out' : 'Buy'}
          </StyledButton>
        )}

        {!props.isMine && props.auction.isFinished && props.auction?.quantityPurchased?.gt(0) && (
          <StyledButton
            style={isAlreadyClaimed ? { width: '70%' } : {}}
            disabled={isLoading || isAlreadyClaimed}
            onClick={handleOnClaim}
          >
            {isAlreadyClaimed ? 'Already Claimed' : 'Claim'}
          </StyledButton>
        )}
      </CardActions>
    </StyledCardContainer>
  );
}

import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Collapse, List, ListItem, ListItemIcon, ListItemProps, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const StyledListItem = styled(({ active, ...other }) => <ListItem {...other} />)`
  &,
  * {
    color: #8a8a8a;
  }
  margin-top: 18px;
  width: 100%;
  padding-left: 0;
  .MuiListItemIcon-root {
    min-width: 20px;
    margin-right: 17px;
  }
  &:hover {
    &,
    * {
      color: #fff;
    }
  }
  ${props =>
    props.active &&
    `
    &, * {
      color: #ed2f58;
    }
    
    &:hover {
      &, * {
        color: #ed2f58;
      }
    }
  `}
`;

const StyledListItemText = styled(({ secondaryText, isBalance, ...other }) => <ListItemText {...other} />)`
  ${props =>
    props.isBalance &&
    `
      flex: unset;
      width: 5rem;
      .MuiTypography-root: {
        margin-right: 0;
      }
    `}
  .MuiListItemText-primary {
    text-transform: none;
    font-family: Muli;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 25px;
    ${props =>
      props.secondaryText &&
      `
      font-weight: 300;
    `}
  }
`;

type SidebarItemType = {
  text: string;
  Icon?: ReactElement;
  secondaryText?: string;
};

const SidebarItem = (
  props: {
    active?: boolean;
    collapse?: boolean;
    collapseItems?: SidebarItemType[];
  } & SidebarItemType &
    ListItemProps,
) => {
  const { text, Icon, collapse, collapseItems, ...rest } = props;
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  if (collapse && collapseItems) {
    return (
      <>
        <StyledListItem style={{ margin: 0 }} active={open} button onClick={handleClick}>
          <ListItemIcon>{Icon}</ListItemIcon>
          <StyledListItemText primary={text} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </StyledListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {collapseItems.map((item, index) => (
              <StyledListItem key={index + item.text} style={{ margin: 0, padding: '4px 0 4px 0' }} button>
                {/* <ListItemIcon>{item.Icon}</ListItemIcon> */}
                <StyledListItemText isBalance primaryTypographyProps={{ style: { fontWeight: 700 } }} primary={item.text} />
                <StyledListItemText isBalance secondaryText primary={item.secondaryText} />
              </StyledListItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <StyledListItem {...rest} button>
      <ListItemIcon>{Icon}</ListItemIcon>
      <StyledListItemText primary={text} />
    </StyledListItem>
  );
};

export default SidebarItem;

import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import classNames from 'classnames';
// import TopBar, { TopBarProps } from './TopBar';
import { TopBarProps } from './TopBar';

const Main = styled.div`
  background-color: #1D0E2D;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 295px auto 350px;
  margin: auto;
  height: 100vh;
  > :not(.top-bar):not(.page-footer-container):not(.tabs-container) {
    flex: 1;
    overflow-y: auto;
  }
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  topBar?: TopBarProps;
}

export const PageContext = React.createContext<HTMLDivElement | null>(null);

export default function Page({ children, className, topBar }: Props) {
  const [pageFooterContainer, setPageFooterContainer] = React.useState<HTMLDivElement | null>(null);
  return (
    <PageContext.Provider value={pageFooterContainer}>
      <Main className={classNames(className)}>
        <Content>{pageFooterContainer && children}</Content>
        <div ref={setPageFooterContainer} className="page-footer-container" />
      </Main>
    </PageContext.Provider>
  );
}

export function PageFooter({ children }: Props) {
  const pageFooterContainer = React.useContext(PageContext);
  if (!pageFooterContainer) {
    throw new Error('PageFooterContainer is null');
  }
  return ReactDOM.createPortal(children, pageFooterContainer);
}

import { apiErrors } from '../consts/api-errors';

export interface ApiErrorObject {
  isApiError: true;
  code: keyof typeof apiErrors;
  stack?: string;
}

export default class ApiError extends Error {
  code: keyof typeof apiErrors;
  status = 400;
  constructor(code: keyof typeof apiErrors, status?: number) {
    super(`Api error [${code}]`);
    this.code = code;
    if (typeof status !== 'undefined') {
      this.status = status;
    }
  }
  toObject(): ApiErrorObject {
    return {
      isApiError: true,
      code: this.code,
      stack: process.env.NODE_ENV !== 'production' ? this.stack : undefined,
    };
  }
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static isApiErrorObject(error: any): error is ApiErrorObject {
    if (typeof error === 'object' && error && error.isApiError) {
      return true;
    }
    return false;
  }
}

/* eslint-disable no-console */
import React from 'react';
import Page from '../../../../Page';
import LeftSidebar from './LeftSidebar';
import MainContent from './MainContent';
import RightSidebar from './RightSidebar';

console.log(`Website node env: ${process.env.NODE_ENV}`);
console.log(`Website react env: ${process.env.REACT_APP_ENV}`);
export default function Home() {
  return (
    <Page>
      <LeftSidebar />
      <MainContent />
      <RightSidebar />
    </Page>
  );
}

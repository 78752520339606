import { Card } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { EtherProviderBag } from '../providers/EtherProvider';
import NFTBidForm from './NFTBidForm'
import SkulloidForm from './SkulloidForm';

export const StyledFormCardContainer = styled(Card)`
  position: relative;
  min-height: 450px;
  width: 100%;
  background-color: #181120;
  color: #ffffff;
  margin: auto;
  margin-top: 22px;
`;

export interface AuctionProps {
  etherBag: EtherProviderBag;
}

type NFTBidFormProps = AuctionProps & {
  type: 'skulloid' | 'pixucat';
}

export default function NFTForm(props: NFTBidFormProps){
  if(props.type === 'skulloid'){
    return <SkulloidForm etherBag={props.etherBag} />
  } else {
    return <NFTBidForm etherBag={props.etherBag} />
  }
}

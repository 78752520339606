import React from 'react';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useGlobalState } from '../../../../providers/GlobalStateProvider';
import NFTCard from '../../../../NFTCard';
import NFTForm from '../../../../nft-forms';
import { useEtherContext } from '../../../../providers/EtherProvider';
// import api from '../../../../../utils/api';
// import { useSnackbar } from '../../../../providers/SnackbarProvider';
// import { useModal } from '../../../../providers/ModalProvider';
// import { Result } from '../../../../Modals';
import { disabledSkulloids } from '../../../../../consts/general';
import MyNfts from './MyNfts';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const skulloidsGif = require('../../../../../assets/images/skulloids.gif');

interface ClaimRequestInfo {
  sender: string;
  signer: string;
  token: string;
  rewardsToken: string;
  action: string;
  nonce: number;
  auctionId: number;
  tokenTypeId: number;
  itemIds: string[];
}

interface ClaimResult {
  signature: string;
  request: ClaimRequestInfo;
  thereAreMoreItems: boolean;
}

const Main = styled.div`
  max-height: calc(100vh - 78px);
`;

const StyledSwipeableViews = styled(SwipeableViews)`
  *::-webkit-scrollbar {
    display: none;
  }
`;

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: '#ed2f58',
    },
  },
})((props: StyledTabsProps) => (
  <Tabs className="tabs-container" {...props} TabIndicatorProps={{ children: <div /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#fff',
      fontWeight: 300,
      fontSize: '14px',
      fontFamily: 'Muli',
      lineHeight: '18px',
      opacity: 1,
      flex: 1,
      maxWidth: 'unset',
      '&$selected': {
        fontWeight: 800,
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const MainContainer = styled.div`
  color: white;
  max-width: 380px;
  min-width: 380px;
  padding: 0 59px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default function MainContent() {
  const etherBag = useEtherContext();
  // const { error, success } = useSnackbar();
  // const { show, hide } = useModal();
  const { currentHomeTab, setCurrentHomeTab } = useGlobalState();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentHomeTab(newValue);
  };

  // TODO: delete all console-logs
  // const claim = useCallback(
  //   async (auctionId: string, onFinished: (e: boolean) => void) => {
  //     if (!etherBag?.signer) {
  //       return error('Connect your wallet');
  //     }
  //     try {
  //       const { data } = await api.post<ClaimResult>('/tokens/claim', {
  //         sender: etherBag.address,
  //         auctionId: Number(auctionId),
  //         tokenTypeId: 0,
  //       });
  //       const result = await etherBag.contracts.claim?.claim({
  //         sender: etherBag.address,
  //         signer: data.request.signer,
  //         action: data.request.action,
  //         nonce: data.request.nonce,
  //         auctionId: Number(auctionId),
  //         tokenTypeId: 0,
  //         itemIds: data.request.itemIds,
  //         signature: data.signature,
  //       });
  //       await result.wait();
  //       if (!data.thereAreMoreItems) {
  //         success('Claim done!');
  //         onFinished(false);
  //       } else {
  //         onFinished(true);
  //         show(
  //           <Result
  //             title="Claim done!"
  //             content="You have claimed 20 NFT succesfully"
  //             close={() => {
  //               hide();
  //             }}
  //           />,
  //         );
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       onFinished(true);
  //       if (err.message) {
  //         if (err.data?.message?.includes('EXCEEDS_ITEMS_FOR_USER')) {
  //           return error('Something was wrong during transaction');
  //         }
  //         error('Something was wrong during transaction');
  //       } else {
  //         error('Something was wrong');
  //       }
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [etherBag, error, success],
  // );

  // temporarily disabled cats
  // const NFTsCards = etherBag.nftItems.items.map((nft, index) => {
  //   return <NFTCard claim={etherBag.address ? claim : () => {}} key={index + 'NFTCard'} auction={nft} />;
  // });
  const skulloidNFTData = etherBag.skullNFTData;

  return (
    <MainContainer>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/nft" />} />
        <Route
          path="/nft"
          render={() => (
            <div>
              <StyledTabs value={currentHomeTab} onChange={handleChange}>
                <StyledTab label="All" />
                <StyledTab label="My NFT" />
              </StyledTabs>
              <StyledSwipeableViews index={currentHomeTab} onChangeIndex={index => setCurrentHomeTab(index)}>
                <Main>
                  {/* {etherBag.nftItems.loading && (
                    <CircularProgress
                      style={{ margin: 'auto', display: 'block', marginTop: 30 }}
                      size={40}
                      disableShrink
                    />
                  )}
                  {NFTsCards} */}
                  {disabledSkulloids && (
                    <NFTCard
                      key={'Skulloid-coming-soon-ad'}
                      auction={
                        {
                          catImage: skulloidsGif,
                          title: 'Skulloids Coming Soon',
                        } as any
                      }
                      isDemo
                    />
                  )}
                  {!disabledSkulloids && !skulloidNFTData.loading && (
                    <NFTCard
                      key={'buy-skulloid'}
                      auction={
                        {
                          catImage: skulloidsGif,
                          title: 'Skulloid NFTs',
                          isPaused: skulloidNFTData.mintInfo!.paused,
                          itemPrice: skulloidNFTData.mintInfo!.pricePerUnit,
                          tokenSymbol: skulloidNFTData.tokenSymbol!,
                        } as any
                      }
                      skull={true}
                    />
                  )}
                  {/* {!etherBag.nftItems.loading && NFTsCards.length <= 0 && (
                    <StyledCardContainer elevation={5}>
                      <div style={{ display: 'flex', height: 450, justifyContent: 'center', alignItems: 'center' }}>
                        <StyledTitle>{'No auctions available yet'}</StyledTitle>
                      </div>
                    </StyledCardContainer>
                  )} */}
                  <div style={{ height: '5rem ' }} />
                </Main>
                <Main>
                  <MyNfts
                    skull={true}
                    claimSkulloidItem={etherBag.claimSkulloidItem}
                    getSkullsIdsFor={etherBag.getSkullsIdsFor}
                    getTokenIdsFor={etherBag.getTokenIdsFor}
                    checkIfCanClaim={etherBag.checkIfCanClaim}
                    address={etherBag.address}
                  />
                  <div style={{ height: '5rem ' }} />
                </Main>
              </StyledSwipeableViews>
            </div>
          )}
        />
        {/* <Route path="/bid/:nftId" render={() => <NFTForm etherBag={etherBag} type='pixucat' />} /> */}
        {!disabledSkulloids && (
          <Route path="/buy-skulloids" render={() => <NFTForm etherBag={etherBag} type="skulloid" />} />
        )}
        <Route path="/test" component={() => <div>Hello</div>} />
      </Switch>
    </MainContainer>
  );
}

import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const StyledButton = styled(({ withLoadIndicator, loadAmount, ...other }) => <Button {...other} />)`
  text-transform: none;
  border: none;
  cursor: unset;
  height: 40px;
  border-radius: 15px 15px 0 15px;
  background-color: #ed2f58;
  &:disabled {
    background-color: rgba(237, 47, 88, 0.5);
    color: rgba(255, 255, 255, 0.5);
  }
  ${props =>
    props.withLoadIndicator &&
    `
    height: 38px;
    background: rgb(255,244,0);
    background: linear-gradient(90deg, rgba(255,244,0,1) ${props.loadAmount}%, rgba(147,150,0,1) ${props.loadAmount}%, rgba(147,150,0,1) 100%);
  `}
  width: 100%;
  color: #ffffff;
  font-family: 'Fugaz One';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  &:hover {
    background-color: #ed2f58;
    opacity: 0.8;
  }
  .prefix {
    font-family: 'Muli';
    font-weight: 300;
    margin-right: 5px;
  }
  .amount {
    font-family: 'Muli';
    font-weight: 800;
  }
`;

// color: #ffffff;
// font - family: 'Muli';
// font - size: 20px;
// font - weight: 800;
// line - height: 25px;
// text - align: center;
export default StyledButton;

import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';
import HyperLink from '../../../../HyperLink';
// import { ReactComponent as IconFacebook } from '../../../../../assets/icons/facebook.svg';
import { ReactComponent as IconTelegram } from '../../../../../assets/icons/telegram.svg';
import { ReactComponent as IconTwitter } from '../../../../../assets/icons/twitter.svg';
// import { ReactComponent as IconWhastapp } from '../../../../../assets/icons/whatsapp.svg';
import { ReactComponent as InstagramIcon } from '../../../../../assets/icons/instagram.svg';

const RightSidebarContainer = styled.div`
  color: white;
  border-left: 1px solid #4a3e57;
  padding-left: 59px;
`;

const Fill = styled.div`
  height: 100px;
  width: 100%;
`;

const ItemLink = styled.div`
  color: #ffffff;
  font-family: Muli;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  & > :nth-child(2) {
    margin-left: 20px;
  }
`;

export default function RightSidebar() {
  return (
    <RightSidebarContainer>
      <Fill />
      <ItemLink>
        Follow us
      </ItemLink>
      {/* <ItemLink>
        <SvgIcon component={IconFacebook} viewBox="0 0 24 24" />
        <HyperLink href="#">Facebook</HyperLink>
      </ItemLink> */}
      <ItemLink>
        <SvgIcon component={IconTwitter} viewBox="0 0 512 512" />
        <HyperLink href="https://twitter.com/pixuverse">Twitter</HyperLink>
      </ItemLink>
      {/* <ItemLink>
        <SvgIcon component={IconWhastapp} viewBox="0 0 24 24" />
        <HyperLink href="#">Whatsapp</HyperLink>
      </ItemLink> */}
      <ItemLink>
        <SvgIcon component={IconTelegram} viewBox="0 0 24 24" />
        <HyperLink href="https://t.me/joinchat/f79hn60Y6MhkYmFh">Telegram</HyperLink>
      </ItemLink>
      <ItemLink>
        <SvgIcon component={InstagramIcon} viewBox="0 0 20.522 20.522"/>
        <HyperLink href="https://www.instagram.com/pixuverse/">Instagram</HyperLink>
      </ItemLink>
    </RightSidebarContainer>
  );
}

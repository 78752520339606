import React, { Component, PropsWithChildren } from 'react';
import * as Sentry from '@sentry/browser';
import { UserRecord } from '../../../../shared/types/db';
import CatastrophicError from './CatastrophicError';

export default class ErrorBoundary extends Component<
  PropsWithChildren<{ dbUser?: UserRecord }>,
  { hasError: boolean }
> {
  constructor(props: PropsWithChildren<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      if (this.props.dbUser) {
        const { id, email, username } = this.props.dbUser;
        scope.setUser({ id, email, username });
      }
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return <CatastrophicError />;
    }

    return this.props.children;
  }
}

import React from 'react';
import styled from 'styled-components';

const A = styled.a`
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
`;

export default function HyperLink({ href, children }: React.PropsWithChildren<{ href: string }>) {
  return (
    <A href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </A>
  );
}
